import { useLayoutEffect } from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

interface IViewportZoomProps {
  disabled?: boolean;
  scalable?: boolean;
}

export function useViewportZoom(props: IViewportZoomProps) {
  const { scalable, disabled } = props;
  const { isMobile } = useEnvironment();

  useLayoutEffect(() => {
    if (!isMobile || disabled) {
      return;
    }

    const $meta = document.head.querySelector('meta[name="viewport"]');
    const original = $meta?.getAttribute('content')?.split(',') ?? [];

    $meta?.setAttribute(
      'content',
      cls(original.join(','), {
        ',maximum-scale=1': true, // prevent auto zooming on input focus
        ',user-scalable=no': !scalable, // disable manual zooming
      }),
    );

    return () => {
      $meta?.setAttribute('content', original.join(','));
    };
  }, [isMobile, scalable, disabled]);
}
